import Vue from 'vue'
import App from './App.vue'
import router from "./router/index";
import "./assets/style/index.css";
import mixin from "./mixin/index";
import store from "./store/index";
import $axios from './utils/request';
import "./components/Vant";
// rem
import "amfe-flexible";

// 把变量挂载到vue的原型上
Vue.prototype.$axios = $axios;
Vue.mixin(mixin);
Vue.config.productionTip = false
new Vue({
  store,
  router,
  render: h => h(App)
}).$mount("#app");