<template>
  <div class="app-wrapper">
      <app-main/>
  </div>
</template>
<script>
import AppMain from "./AppMain";
export default {
  name: "layout",
  components: {AppMain}
}
</script>

<style scoped>
.app-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}
.app-wrapper .mobile.openSidebar{
  position: fixed;
  top: 0;
}
.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}
</style>
