import Vue from 'vue';
import Vuex from 'vuex';
import tagsView from './modules/tagsView'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex);
const config = {
    plugins: [createPersistedState()],
    state: {
        isLogin: false,
        platform:'',
        userId: '',
        userInfo: '',
        isAuth: false,
        isBind: false,
        username: '',
        token: ''
    },
    getters: {
        isLogin: state => state.isLogin,
        isAuth: state => state.isAuth,
        isBind: state => state.isBind,
        userId: state => state.userId,
        platform: state => state.platform,
        userInfo: state => state.userInfo,
        token: state => state.token,
        username: state => state.username
    },
    mutations: {
        updateLogin(state, payload) {
            state.isLogin = payload;
        },
        updateToken(state, payload) {
            state.token = payload;
        },
        updateUsername(state, payload) {
            state.username = payload;
        },
        updateAuth(state,payload){
            state.isAuth = payload
        },
        updateBind(state,payload){
            state.isBind = payload
        },
        updateUserId(state,payload){
            state.userId = payload
        },
        updatePlatform(state,payload){
            state.platform = payload
        },
        updateUserInfo(state,payload){
            state.userInfo = payload
        }

    },
    actions: {},
    modules: {
        tagsView,
    }
}
const store = new Vuex.Store(config);
export default store;