import Vue from "vue";
import Router from "vue-router";
import store from "../store/index";
import Layout from "../views/layout/Layout"
Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: "/",
      redirect: "/index"
    },
    {
      path: "/index",
      component: () => import("../views/index/index"),
      name: "一网通首页",
      meta: {
        title: "一网通首页"
      }
    },
    {
      path: "/login/index",
      component: () => import("../views/login/index"),
      name: "用户登录",
      meta: {
        title: "用户登录"
      }
    },
    {
      path: "/compulsive/bzRead",
      component: () => import("../views/compulsive/bzRead"),
      name: "业务办理须知",
      meta: {
        title: "业务办理须知"
      }
    },
    {
      path: "/ikm/index",
      component: () => import("../views/ikm/index"),
      name: "i昆明用户登录",
      meta: {
        title: "i昆明用户登录"
      }
    },
    {
      path: "/kmwwechart/official",
      component: () => import("../views/kmwwechart/official"),
      name: "昆明自来水公众号",
      meta: {
        title: "昆明自来水公众号"
      }
    },
    {
      path:"/zwfw/",
      component:Layout,
      name:"zwfwMain",
      redirect: "/zwfw/index",
      meta:{title: "政务服务"},
      children:[
        {
          path: "index",
          name:'zwfwIndex',
          meta: {
            title: "政务服务"
          },
          component: () => import("../views/zwfw/index")
        },
        {
          path: "out",
          name:'zwfuOut',
          meta: {
            title: "退出"
          },
          component: () => import("../views/zwfw/out")
        },
      ]
    },
    {
      path:"/info/",
      component:Layout,
      name:"infoMain",
      redirect: "/info/index",
      meta:{title: "信息"},
      children:[
        {
          path: "/info/index",
          component: () => import("../views/info/index"),
          name: "信息列表",
          meta: {
            title: "信息列表"
          }
        },
        {
          path: "/info/info",
          component: () => import("../views/info/info"),
          name: "信息内容",
          meta: {
            title: "信息内容"
          }
        }
      ]
    },
    {
      path:"/payment/",
      component:Layout,
      name:"paymentMain",
      redirect: "/info/hint",
      meta:{title: "交费"},
      children:[
        {
          path: "hint",
          component: () => import("../views/payment/hint"),
          name: "paymentHint",
          meta: {
            title: "交费提示"
          }
        },
        {
          path: "index",
          component: () => import("../views/payment/index"),
          name: "paymentIndex",
          meta: {
            title: "水费交纳"
          }
        },
        {
          path: "billDetail",
          component: () => import("../views/payment/billDetail"),
          name: "billDetail",
          meta: {
            title: "账单详情"
          }
        },
        {
          path: "settlement",
          component: () => import("../views/payment/settlement"),
          name: "settlement",
          meta: {
            title: "结算信息核算"
          }
        },
        {
          path: "convenient",
          component: () => import("../views/payment/convenient"),
          name: "paymentConvenient",
          meta: {
            title: "快捷查交水费"
          }
        }
      ]
    },
    {
      path:"/transfer/",
      component:Layout,
      name:"transferMain",
      redirect: "noredirect",
      meta:{title: "过户"},
      children:[
        {
          path: "transferAdd",
          component: () => import("../views/transfer/transferAdd"),
          name: "transferAdd",
          meta: {
            title: "居民过户"
          }
        },
        {
          path: "transferView",
          component: () => import("../views/transfer/transferView"),
          name: "transferView",
          meta: {
            title: "居民过户 办理进度"
          }
        },
      ]
    },
    {
      path:"/other/",
      component:Layout,
      name:"otherMain",
      redirect: "/other/error",
      meta:{title: "其他"},
      children:[
        {
          path: "uploaddemo",
          name:"uploaddemo",
          meta: {
            title: "上传示例"
          },
          component: () => import("../views/other/uploaddemo")
        },
        {
          path: "help",
          name:"help",
          meta: {
            title: "帮助"
          },
          component: () => import("../views/other/help")
        },
        {
          path: "helpDetail",
          name:"helpDetail",
          meta: {
            title: "帮助详细"
          },
          component: () => import("../views/other/helpDetail")
        },
        {
          path: "error",
          component: () => import("../views/other/errot"),
          name: "error",
          meta: {
            title: "错误"
          }
        }
      ]
    },
    {
      path:"/user/",
      component:Layout,
      name:"userMain",
      redirect: "/user/index",
      meta:{title: "用户中心"},
      children:[
        {
          path: "index",
          component: () => import("../views/user/index"),
          name: "userIndex",
          meta: {
            title: "个人信息"
          }
        },
        {
          path: "modifyPhone",
          component: () => import("../views/user/modifyPhone"),
          name: "modifyPhone",
          meta: {
            title: "修改电话"
          }
        },
        {
          path: "modifyPass",
          component: () => import("../views/user/modifyPass"),
          name: "modifyPass",
          meta: {
            title: "修改密码"
          }
        },
        {
          path: "bindAccount",
          component: () => import("../views/user/bindAccount"),
          name: "bindAccount",
          meta: {
            title: "户号绑定"
          }
        }
      ]
    },
    {
      path: "/report/",
      component: Layout,
      name: "reportMain",
      redirect: "/report/index",
      meta: {title: "报漏报修22"},
      children: [
        {
          path: "index",
          component: () => import("../views/report/index"),
          name: "reportIndex",
          meta: {
            title: "报漏报修33"
          }
        },
        {
          path: "infoView",
          component: () => import("../views/report/infoView"),
          name: "reportInfoView",
          meta: {
            title: "办理结果"
          }
        },

      ]
    },
    {
      path: "/bzAppointment/",
      component: Layout,
      name: "bzAppointment",
      redirect: "/bzAppointment/index",
      meta: {title: "用水报装预约"},
      children: [
        {
          path: "index",
          component: () => import("../views/bzAppointment/index"),
          name: "bzAppointmentIndex",
          meta: {
            title: "用水报装预约"
          }
        },
        {
          path: "infoView",
          component: () => import("../views/bzAppointment/index"),
          name: "bzAppointmentView",
          meta: {
            title: "办理结果"
          }
        },

      ]
    },
    {
      path: "*",
      name:'notfound',
      component: () => import("../views/other/notfound"),
      meta:{title:'找不到页面'}
    }
  ]
});

router.beforeEach((to, from, next) => {
  // console.log(to)
  let { title, needLogin } = to.meta;
  let { isLogin } = store.state;
  document.title = title;
  if (needLogin && !isLogin) {
    next({
      path: "/login"
    });
  } else {
    next();
  }
});
export default router;
