import Vue from "vue";
import {
    Icon,
    Tab,
    Tabs,
    List,
    Cell,
    Button,
    Popup,
    Field,
    Dialog,
    Toast,
    Swipe,
    SwipeItem,
    Area,
    NavBar,
    CellGroup,
    Image,
    Sticky,
    Lazyload,
    Grid,
    GridItem,
    Col,
    Row,
    Divider,
    CountDown,
    Collapse,
    CollapseItem,
    Search,
    Pagination,
    Image as VanImage,
    Loading,
    Overlay,
    Tag,
    Form,
    Uploader,
    PullRefresh,
    Empty,
    Checkbox,
    CheckboxGroup,
    Radio,
    RadioGroup,
    Picker,
    NoticeBar,
    DropdownMenu,
    DropdownItem
} from "vant";

Vue.use(Icon);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(List);
Vue.use(Cell);
Vue.use(Button);
Vue.use(Popup);
Vue.use(Field);
Vue.use(Dialog);
Vue.use(Toast);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Area);
Vue.use(NavBar);
Vue.use(CellGroup);
Vue.use(Image);
Vue.use(Sticky);
Vue.use(Lazyload);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Col);
Vue.use(Row);
Vue.use(Divider);
Vue.use(CountDown);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Search);
Vue.use(Pagination);
Vue.use(VanImage);
Vue.use(Loading);
Vue.use(Overlay);
Vue.use(Tag);
Vue.use(Form);
Vue.use(Uploader);
Vue.use(PullRefresh);
Vue.use(Empty);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Picker);
Vue.use(NoticeBar);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
